import React, { useState } from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import cx from "classnames";
import { Link } from "gatsby";

import MobileMenu from "./MobileMenu/MobileMenu";
import { BrassLogo } from "../../../assets/media/svgs/brand";

import { NAVBAR_CONFIG, SubItemParams } from "./config";

import * as styles from "./Navbar.module.scss";
import {
  APP_LOGIN_URL,
  APP_SIGNUP_URL,
  LAUNCHPAD_LOGIN_URL,
  LUNCHPAD_APP_SIGN_UP_URL,
} from "data/config";
import { uniqueId } from "lodash";
import { CountrySwitcher } from "./CountrySwitcher";

export interface RenderNavLinkProps {
  label: React.ReactNode | string;
  route: string;
  subItems: SubItemParams[] | undefined;
  isExternalUrl?: boolean;
  className?: string;
  partiallyActive?: boolean;
}

interface NavbarProps {
  location: Location;
}

const Navbar: React.FC<NavbarProps> = ({ location }) => {
  const [activeSubSection, setActiveSubSection] = useState<React.ReactNode>("");
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const toggleMobileMenu = () => setMobileMenuIsOpen(!mobileMenuIsOpen);

  const isLaunchpad = location.pathname.includes("launchpad");

  const signUpText = isLaunchpad ? "Start your company" : "Open an account";

  const signUpLink = isLaunchpad ? LUNCHPAD_APP_SIGN_UP_URL : APP_SIGNUP_URL;

  const loginLink = isLaunchpad ? LAUNCHPAD_LOGIN_URL : APP_LOGIN_URL;

  const renderNavLink = (params: RenderNavLinkProps) => {
    const { label, route, subItems, isExternalUrl = false, className } = params;
    if (!subItems) {
      if (isExternalUrl) {
        return (
          <a
            className={className}
            href={route}
            target="_blank"
            rel="noopener noreferrer"
          >
            {label}
          </a>
        );
      }
      return (
        <Link className={className} to={route || "/"}>
          {label}
        </Link>
      );
    }
    return (
      <div
        onMouseEnter={() => {
          setActiveSubSection(label);
        }}
        onMouseLeave={() => {
          setActiveSubSection("");
        }}
        className={styles.Navbar_container_item_withDropdown}
      >
        <div
          key={route}
          className={cx(
            styles.Navbar_container_item_withDropdown_link,
            className
          )}
        >
          {label}
        </div>
        <div
          className={cx(styles.Navbar_container_item_withDropdown_subMenu, {
            [styles.Navbar_container_item_withDropdown_subMenu_active]:
              activeSubSection === label,
          })}
        >
          {subItems.map(({ label, desc, route, isExternalUrl }) => {
            const SubLinkComponent: any = isExternalUrl ? "a" : Link;
            const subLinkComponentPorps = {
              key: uniqueId("nav-sub-link"),
              className: styles.Navbar_container_item_withDropdown_subMenu_item,
              onClick: () => {
                setActiveSubSection("");
              },
            };

            if (isExternalUrl) {
              subLinkComponentPorps["href"] = route;
              subLinkComponentPorps["target"] = "_blank";
              subLinkComponentPorps["rel"] = "noopener noreferrer";
            } else subLinkComponentPorps["to"] = route;

            return (
              <SubLinkComponent {...subLinkComponentPorps}>
                <h6>{label}</h6>
                <p>{desc}</p>
              </SubLinkComponent>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.Navbar}>
      <div className={styles.Navbar_container}>
        <div className={styles.Navbar_container_left}>
          <Link className={styles.Navbar_container_left_logo} to="/">
            <BrassLogo />
          </Link>
          {NAVBAR_CONFIG.left.map((config) => {
            return (
              <React.Fragment key={uniqueId("nav-link-left")}>
                {renderNavLink({
                  className: styles.Navbar_container_left_link,
                  ...(config as Required<typeof config>),
                })}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.Navbar_container_right}>
          {NAVBAR_CONFIG.right.map((config) => {
            return (
              <React.Fragment key={uniqueId("nav-link-right")}>
                {renderNavLink({
                  className: styles.Navbar_container_right_link,
                  ...(config as Required<typeof config>),
                })}
              </React.Fragment>
            );
          })}
          <a
            className={styles.Navbar_container_right_link}
            href={loginLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Login
          </a>
          <Button
            as={"a"}
            className={styles.Navbar_container_right_btn}
            variant={ButtonVariant.Primary}
            href={signUpLink}
            target="_blank"
          >
            {signUpText}
          </Button>
          <CountrySwitcher />
        </div>
        <div
          onClick={toggleMobileMenu}
          role="button"
          onKeyPress={() => null}
          className={cx(styles.Navbar_container_icon, {
            [styles.Navbar_container_icon__close]: mobileMenuIsOpen,
          })}
        >
          <div className={styles.Navbar_container_icon_stroke} />
          <div className={styles.Navbar_container_icon_stroke} />
          <div className={styles.Navbar_container_icon_stroke} />
        </div>
      </div>
      <MobileMenu
        toggleMobileMenu={toggleMobileMenu}
        open={mobileMenuIsOpen}
        isLaunchpad={isLaunchpad}
      />
    </div>
  );
};

export default Navbar;
